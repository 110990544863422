import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Car } from '@ideenherd';
import { ConvertHelper, DataService } from '@ideenherd/core/data';
import { Subscription } from 'rxjs';
import { SINECTROUTES } from '../../app-routing.module';
import { AppService } from '../../app.service';
import { CarViewModel } from '../view-models/car.view-model';
import { EventViewModel } from '../view-models/event.view-model';

declare let cordova: any;

@Component({
  selector: 'ideenherd-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit {
  public eventDetail: EventViewModel;
  private subscriptions: Subscription = new Subscription();
  public convertHelper = ConvertHelper;

  constructor(
    private router: Router,
    public appService: AppService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.appService.showBackButton = true;

    this.eventDetail = this.appService.eventDetail;
    if (this.appService.carList.length === 0) {
      this.subscriptions.add(this.dataService.getAllCars<Car[]>().subscribe((cars) => {
        cars.forEach((car) => {
          this.appService.carList.push(new CarViewModel(car));
        });
      })
      );
    }
    this.subscriptions.add(this.appService.backButtonS$.subscribe(() => {
      if (window['deviceReady'] == true) {
        document.addEventListener("backbutton", function (e) {
          this.backToOrderList();
        });
      }
      this.backToOrderList();
    }));
  }

  private backToOrderList = (): void => {
    this.router.navigateByUrl('/event-verifier');
    this.appService.showBackButton = false;
  }

  getCarScanner() {
    this.router.navigate([
      SINECTROUTES.EVENT_SCANNER.replace(':path', SINECTROUTES.EVENT_DETAIL),
    ]);
  }

  getCarDetail() {
    this.router.navigate([SINECTROUTES.CAR_OVERVIEW]);
  }

}
