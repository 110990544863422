import { Injectable } from '@angular/core';
import { TokenModel } from '../models/login-info.model';
const ACCESS_TOKEN = 'access_token';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor() { }

    public getToken() {
        return localStorage.getItem('access_token');
    }

    public saveToken(loginInfo: TokenModel): void {
        localStorage.setItem('access_token', loginInfo.access_token);
    }


}
