import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from 'libs/core/configuration/src/lib/config.service';
import { TokenService } from './token.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private configService: ConfigService, private tokenService: TokenService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        const token = this.tokenService.getToken();
        if (token && request.url !== this.configService?.configuration?.WebApi?.ServiceURLs.GenerateToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                    'content-type': 'application/json'
                }
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }));
    }
}