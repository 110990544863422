import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IToast } from '@ideenherd';

import { CarViewModel } from './core/view-models/car.view-model';
import { EventViewModel } from './core/view-models/event.view-model';
import { UserViewModel } from './core/view-models/user.view-model';
import { AppointmentViewModel } from './core/view-models/appointment.view-model';
@Injectable({
  providedIn: 'root',
})
export class AppService {
  public eventDetail: EventViewModel;
  public carDetail: CarViewModel;
  public appointmentId: number;
  public updatedDriverindex: number;
  public driversList: UserViewModel[] = [];
  public carList: CarViewModel[] = [];
  public appointmentList: AppointmentViewModel[] = [];
  private backButtonSubject = new Subject<void>();
  public backButtonS$ = this.backButtonSubject.asObservable();
  public showBackButton: boolean = false;
  private notificationSubject: Subject<IToast> = new Subject();
  public notification$ = this.notificationSubject.asObservable();
  public currentDriverlist: UserViewModel[] = [];

  public callNotification(notification: IToast): void {
    this.notificationSubject.next(notification);
  }
  public callBackButton(): void {
    this.backButtonSubject.next();
  }
}
