import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreConfigService } from '@ideenherd/core/configuration';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { ConvertHelper } from './helpers';

@Injectable({
    providedIn: 'root'
})
export class PersistenceService {
    private startAppointmentUri: string;
    private appointmentByIdUri: string;
    constructor(private http: HttpClient, private configService: CoreConfigService, private dataService: DataService) {
        this.startAppointmentUri = this.configService.configuration.WebApi.ServiceURLs.StartAppointment;
        this.appointmentByIdUri = this.configService.configuration.WebApi.ServiceURLs.AppointmentById;
    }

    public addAppointment(query): Observable<any> {
        return this.dataService.executeWebApiPostMethod((ConvertHelper.resolveStringPlaceholders(this.startAppointmentUri, this.dataService.tenant)), query);
    }

    public updateAppontmentById(id, query): Observable<any> {
        return this.dataService.executeWebApiPatchMethod((ConvertHelper.resolveStringPlaceholders(this.appointmentByIdUri, this.dataService.tenant, id)), query);
    }

}

export interface IAppointment {
    a_user_id: number;
    users?: string[];
    a_additional_users?: string;
    a_car_id: number;
    a_event_id: number;
    a_check_in_time: Date;
    a_check_out_time?: Date;
}




