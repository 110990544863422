import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TokenService } from './services/token.service';
import { Interceptor } from './services/interceptor';

@NgModule({
  imports: [CommonModule],
  providers: [TokenService, Interceptor]
})
export class AuthenticationModule { }
