import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { ToastComponent } from './toast/toast.component';
import { DxToastModule } from 'devextreme-angular';

@NgModule({
  imports: [CommonModule, ZXingScannerModule, DxToastModule],
  declarations: [QrScannerComponent, ToastComponent],
  exports: [QrScannerComponent, ToastComponent],
})
export class IdeenherdLibraryModule { }
