import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreConfigService } from '@ideenherd/core/configuration';
import { TokenModel } from 'libs/authentication/src/lib/models/login-info.model';
import { TokenService } from 'libs/authentication/src/lib/services/token.service';
import { first, Observable } from 'rxjs';
import { ConvertHelper } from './helpers';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private readonly eventDetailUri: string;
  private readonly carDetailUri: string;
  private readonly participantDriverUri: string;
  private readonly carsListUri: string;
  private readonly accreditationListUri: string;
  private readonly tenantListUri: string;
  public tenant: string;

  constructor(private http: HttpClient, private configService: CoreConfigService, private tokenService: TokenService) {
    this.eventDetailUri = this.configService.configuration.WebApi.ServiceURLs.EventDetail;
    this.carDetailUri = this.configService.configuration.WebApi.ServiceURLs.CarDetail;
    this.participantDriverUri = this.configService.configuration.WebApi.ServiceURLs.ParticipantDriverDetail;
    this.carsListUri = this.configService.configuration.WebApi.ServiceURLs.CarsList;
    this.accreditationListUri = this.configService.configuration.WebApi.ServiceURLs.AccreditationList;
    this.tenantListUri = this.configService.configuration.WebApi.ServiceURLs.TenantList;

    this.prepareToken().pipe(first()).subscribe(token => {
      tokenService.saveToken(token);

      this.getTenants().pipe(first()).subscribe(tenant => {
        this.tenant = tenant[0];
      });
    });
  }

  public getEventByCode<T>(query: ILogin): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.eventDetailUri, this.tenant, query.login));
  }

  public getCarDetailByCode<T>(query: ICode): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.carDetailUri, this.tenant, query.code));
  }

  public getParticipantByCode<T>(query: ICode): Observable<T> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.participantDriverUri, this.tenant, query.code));
  }

  public getAllCars<Car>(): Observable<Car> {
    return this.executeWebApiGetMethod(ConvertHelper.resolveStringPlaceholders(this.carsListUri, this.tenant));
  }

  public getTenants<T>(): Observable<T> {
    return this.executeWebApiGetMethod(this.tenantListUri);
  }

  public getAccreditationList<T>(): Observable<T> {
    return this.executeWebApiGetMethod(this.accreditationListUri);
  }

  public executeWebApiGetMethod<T>(url: string, params?: any): Observable<T> {
    return this.http.get<T>(url, { params: params });
  }

  public executeWebApiPostMethod(url: string, body: any): Observable<any> {
    return this.http.post(url, body);
  }

  public executeWebApiPutMethod(url, body): Observable<any> {
    return this.http.put(url, body);
  }

  public executeWebApiPatchMethod(url, body): Observable<any> {
    return this.http.patch(url, body);
  }

  public prepareToken(): Observable<TokenModel> {
    const headers = new HttpHeaders().set('Authorization', this.configService.configuration.OAuth2.ClientId);
    return this.http.get<TokenModel>(this.configService.configuration.WebApi.ServiceURLs.GenerateToken, { headers: headers })
  }
}

export interface ILogin {
  login: string
}

export interface ICode {
  code: string
}


