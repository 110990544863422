import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreConfigService } from '@ideenherd/core/configuration';
import { Interceptor } from 'libs/authentication/src';

@NgModule({
  imports: [CommonModule],
  providers: [
    CoreConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }]
})
export class CoreDataModule { }
