<dx-scroll-view>
  <div class="background-body" style="padding: 8px" fxFlex="100" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutGap="8PX" fxFill>
    <div fxLayout="row">
      <div fxFlex="100">
        <h1 style="color: aliceblue;font-family: 'Source Sans Pro', sans-serif">{{QrCodeTitle}}</h1>
      </div>
    </div>

    <div class="qr-code-style" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="70">
        <ideenherd-qr-scanner #scanner (onScanSuccess)="scanSuccess($event)"></ideenherd-qr-scanner>
      </div>
    </div>

  </div>
</dx-scroll-view>
<dx-toast #toast [type]="'error'" [message]="'Ungültiger Code'" [displayTime]="3000" [position]="toastPosition"
  [width]="'auto'">
</dx-toast>