export class SinectEvent {
    e_id: number;
    e_name: string;
    e_info: string;
    e_place: string;
    e_street: string;
    e_zip: string;
    e_city: string;
    e_email: string;
    e_phone: string;
    e_country: string;
    e_s_date: string;
    e_e_date: string;
    e_s_date_hotel: string;
    e_e_date_hotel: string;
    e_t_arrival_start: string;
    e_t_arrival_end: string;
    e_t_departure_start: string;
    e_t_departure_end: string;
    e_s_hour: string;
    e_e_hour: string;
    e_eticket: number;
    e_testdrive_time_limit: number;
    e_google_maps: string;

    constructor(eventData) {
        Object.assign(this, eventData);
    }
}

export interface EventCode {
    login: string;
}