<div fxLayout="column" style="padding: 8px" fxLayoutGap="15px" fxFill>
  <div fxLaout="row">
    <div class="details" style="margin-top: 20px;">
      <h3>{{ eventDetail.name }}</h3>
      <p>
        <b>Datum /Uhrzeit: </b>
        <span>{{ eventDetail.startDate }}</span>
      </p>
      <p>
        <b>Beschreibung: </b>
        <span>{{ eventDetail.info }} </span>
      </p>
    </div>
  </div>

  <div fxLayout="row">
    <dx-button style="text-align: center" class="button-car" [height]="'100%'" [width]="'100%'" type="default"
      text="{{ appService.carList.length }} Fahrzeuge" (onClick)="getCarDetail()">
    </dx-button>
  </div>
  <div fxLaout="row">
    <dx-button class="button-scan" [height]="'100%'" [width]="'100%'" text="Neue Fahrt starten" type="default"
      icon="assets/images/qrcode.svg " (onClick)="getCarScanner()">
    </dx-button>
  </div>
</div>