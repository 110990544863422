<div fxLayout="column" fxFill>
  <div fxFlex="50px" fxLayout="row" class="app-header">
    <dx-toolbar class="header-toolbar">
      <dxi-item location="before" *ngIf="this.appService.showBackButton === true">
        <dx-button class="returnButtonStyle" stylingMode="text" icon="back" type="default" (onClick)="returnButton()"
          [hoverStateEnabled]="false">
        </dx-button>
      </dxi-item>
      <dxi-item location="center" locateInMenu="auto" menuItemTemplate="menuItem">
        <img id="logoImg" src="assets/images/app-logo.svg" />
        <!-- <h2 class="logo-name">SI-nect</h2> -->
      </dxi-item>
      <dxi-item *ngIf="this.appService.showBackButton === true" class="item-style" location="after" locateInMenu="auto"
        menuItemTemplate="menuItem">
        <img class="contextMenuHeader" src="assets/images/ContextMenu.svg" />
      </dxi-item>
    </dx-toolbar>
    <dx-context-menu class="settings-context-menu" target=".contextMenuHeader" showEvent="dxclick"
      [items]="contextMenuItems" [position]="contextMenuPosition">
    </dx-context-menu>
  </div>

  <div class="background-body" fxFlex="calc(100% - 100px)">
    <router-outlet></router-outlet>
  </div>
</div>