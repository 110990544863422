export class ConvertHelper {

  public static toDate(value: string, culture = 'de'): string {
    const date = new Date(value);
    return date.toLocaleDateString(culture, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  public static toTime(value: string): string {
    const date = new Date(value);
    return date.toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' });
  }

  public static resolveStringPlaceholders(str, ...values) {
    const regex = /{\w+}/g;
    const placeholders = str.match(regex);
    let placeHoldersResolver = {};
    values.forEach((value, index) => {
      placeHoldersResolver[placeholders[index]] = value;
    });
    return str.replace(/{\w+}/g, (all) => {
      return placeHoldersResolver[all] || all;
    });
  }

  // Turn enum into array
  public static ToArray(enumeration) {
    let toArray: Object[];
    Object.keys(enumeration).forEach((key) => {
      if (!toArray) toArray = [];
      const obj = {
        id: key,
        label: enumeration[key]
      };
      toArray.push(obj);
    });
    return toArray;
  }
}

export class CompareHelper {
  public static isEmptyObject(obj: any): boolean {
    return !obj || (Object.entries(obj).length === 0 && obj.constructor === Object);
  }
}

export class ObjectHelper {
  /**
* Deep merge two objects.
* @param target
* @param ...sources
*/
  public static mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (ObjectHelper.isObject(target) && ObjectHelper.isObject(source)) {
      for (const key in source) {
        if (ObjectHelper.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          ObjectHelper.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return ObjectHelper.mergeDeep(target, ...sources);
  }

  public static deepClone(objectpassed) {
    if (objectpassed === null || typeof objectpassed !== 'object') {
      return objectpassed;
    }
    var temporary = objectpassed.constructor();
    for (var key in objectpassed) {
      temporary[key] = this.deepClone(objectpassed[key]);
    }
    return temporary;
  }

  public static isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }
}