import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from './app.service';
import { filter } from 'rxjs/operators';
import DevExpress from 'devextreme';
@Component({
  selector: 'ideenherd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public contextMenuPosition: DevExpress.PositionConfig = { at: 'right top', my: 'right bottom' };
  public title = 'si-nect';
  public contextMenuItems = [
    {
      text: 'Logout',
      icon: 'assets/images/bar-code.svg',
      onClick: () => {
        localStorage.removeItem('eventCode');
        this.router.navigate(['/']);
      },
    },
  ];
  constructor(public appService: AppService, private router: Router) { }

  ngOnInit(): void {
    this.redirectWhenReload();
  }

  redirectWhenReload() {
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe((event) => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          this.router.navigate(['/']);
        }
      });
  }

  public returnButton(): void {
    this.appService.callBackButton();
  }
}
