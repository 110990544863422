export enum ToastType {
    CUSTOM = 'custom',
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export interface IToast {
    message : string;
    type : ToastType;
    displayTime? : number;
}
