import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ideenherd-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent {
  @Output() onScanSuccess = new EventEmitter();
  @Output() onScanComplete = new EventEmitter();
  @Output() onScanError = new EventEmitter();
  @Input() enableScanner = true;

  public scanSuccess(event): void {
    this.onScanSuccess.emit(event);
  }

  public scanComplete(event): void {
    this.onScanComplete.emit(event);
  }

  public scanError(event): void {
    this.onScanError.emit(event);
  }
}
