import { EventVerifierComponent } from './core/event-verifier/event-verifier.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventDetailComponent } from './core/event-detail/event-detail.component';
import { CarOverviewComponent } from './core/car-overview/car-overview.component';
import { EventScannerComponent } from './core/event-scanner/event-scanner.component';
import { CarDetailComponent } from './core/car-detail/car-detail.component';

const routes: Routes = [
  {
    path: '',
    component: EventVerifierComponent,
    loadChildren: () =>
      import('./core/event-verifier/event-verifier.module').then(
        (module) => module.EventVerifierModule
      ),
  },
  {
    path: 'event-detail',
    component: EventDetailComponent,
    loadChildren: () =>
      import('./core/event-detail/event-detail.module').then(
        (module) => module.EventDetailModule
      ),
  },
  {
    path: 'car-detail',
    component: CarDetailComponent,
    loadChildren: () =>
      import('./core/car-detail/car-detail.module').then(
        (module) => module.CarDetailModule
      ),
  },

  {
    path: 'car-detail',
    component: CarDetailComponent,
    loadChildren: () =>
      import('./core/car-detail/car-detail.module').then(
        (module) => module.CarDetailModule
      ),
  },
  {
    path: 'event-scanner/:path',
    component: EventScannerComponent,
    loadChildren: () =>
      import('./core/event-scanner/event-scanner.module').then(
        (module) => module.EventScannerModule
      ),
  },
  {
    path: 'car-overview',
    component: CarOverviewComponent,
    loadChildren: () =>
      import('./core/car-overview/car-overview.module').then(
        (module) => module.CarOverviewModule
      ),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export class SINECTROUTES {
  public static readonly EVENT_DETAIL = 'event-detail';
  public static readonly CAR_DETAIL = 'car-detail';
  public static readonly CAR_DETAIL_STEPPER = 'car-detail/:path';

  public static readonly EVENT_SCANNER = 'event-scanner/:path';
  public static readonly CAR_OVERVIEW = 'car-overview';
}
