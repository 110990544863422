import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Car, User } from '@ideenherd';
import { DataService } from '@ideenherd/core/data';
import DevExpress from 'devextreme';
import { DxToastComponent } from 'devextreme-angular';
import { ICode } from 'libs/core/data/src/lib/data.service';
import { IToast, ToastType } from '@ideenherd';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { SINECTROUTES } from '../../app-routing.module';
import { AppService } from '../../app.service';
import { CarViewModel } from '../view-models/car.view-model';
import { UserViewModel } from '../view-models/user.view-model';

@Component({
  selector: 'ideenherd-event-scanner',
  templateUrl: './event-scanner.component.html',
  styleUrls: ['./event-scanner.component.scss']
})
export class EventScannerComponent implements OnInit, OnDestroy {
  @ViewChild('toast') dxToast: DxToastComponent;
  public QrCodeTitle = QrMessages.SCANCAR;
  public toastPosition: DevExpress.PositionConfig = { at: 'top', my: 'center', offset: '0 100' };
  private subscriptions: Subscription = new Subscription();
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dataService: DataService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.appService.backButtonS$.subscribe(() => {
      if (window['deviceReady'] == true) {
        document.addEventListener("backbutton", function (e) {
          this.backToEventDetail();
        });
      }
      this.backToEventDetail();
    }));
    const path = this.activeRoute.snapshot.paramMap.get('path');
    if (path === SINECTROUTES.CAR_DETAIL) {
      if (this.appService.driversList.length === 0 || this.appService.updatedDriverindex === 0) {
        this.QrCodeTitle = QrMessages.SCANDRIVER;
      } else {
        this.QrCodeTitle = QrMessages.SCANCODRIVER;
      }
    }
  }

  private backToEventDetail = (): void => {
    const path = this.activeRoute.snapshot.paramMap.get('path');

    if (path === SINECTROUTES.CAR_DETAIL) {
      this.router.navigateByUrl('/car-detail');
      this.appService.showBackButton = true;
      this.appService.driversList = [...this.appService.currentDriverlist];
    }
    else if (path === SINECTROUTES.CAR_OVERVIEW) {
      this.router.navigateByUrl('/car-overview');
      this.appService.showBackButton = true;
    }
    else {
      this.router.navigateByUrl('/event-detail');
      this.appService.showBackButton = true;
    }

  }
  camerasNotFound(e: Event) {
  }

  cameraFound(e: Event) {
  }

  public scanSuccess(e) {
    const path = this.activeRoute.snapshot.paramMap.get('path');
    const body: ICode = {
      code: e
    }
    if (path === SINECTROUTES.EVENT_DETAIL || path === SINECTROUTES.CAR_OVERVIEW) {
      if (e.includes("_")) {
        const searchTerm = '_';
        const indexOfFirstTerm = e.indexOf(searchTerm);
        const carId = e.substring(e.indexOf(searchTerm, (indexOfFirstTerm + 1)) + 1);
        body.code = carId;
      }
      this.subscriptions.add(
        this.dataService.getCarDetailByCode<Car>(body).subscribe((carDetail) => {
          this.appService.carDetail = new CarViewModel(carDetail);
        }, (error) => {
          this.dxToast.visible = true;
          console.error(error);
        }, () => {
          this.appService.currentDriverlist = [];
          this.router.navigate([SINECTROUTES.CAR_DETAIL]);
        }
        )
      );
    } else if (path === SINECTROUTES.CAR_DETAIL) {
      this.subscriptions.add(this.dataService.getParticipantByCode<User>(body).pipe(takeUntil(this.destroy$)).subscribe(driverDetail => {
        const driver = new UserViewModel(driverDetail);
        driver.currentEventId = this.appService.eventDetail.id;
        const driverAlreadyAssigned = this.appService.driversList.find(driver => driver.id === driverDetail.u_id);
        if ((this.appService.updatedDriverindex || this.appService.updatedDriverindex === 0) && !driverAlreadyAssigned) {
          this.appService.driversList[this.appService.updatedDriverindex] = driver;
          this.appService.currentDriverlist[this.appService.updatedDriverindex] = driver;
        } else if (!this.appService.updatedDriverindex && !driverAlreadyAssigned) {
          this.appService.driversList.push(driver);
          this.appService.currentDriverlist.push(driver);
        } else {
          const message: IToast = {
            message: "Fahrer bereits zugewiesen",
            type: ToastType.INFO
          };
          this.appService.callNotification(message);
        }
      }, error => {
        console.error(error);
        this.dxToast.visible = true;
      }, () => {
        this.destroy$.next();
        this.destroy$.complete();
        this.router.navigate([SINECTROUTES.CAR_DETAIL]);
        this.appService.updatedDriverindex = undefined;
      }))
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }
}

export enum QrMessages {
  SCANCAR = 'Fahrzeug Code scannen',
  SCANDRIVER = 'Fahrer Code scannen',
  SCANCODRIVER = 'Beifahrer Code scannen'
}
