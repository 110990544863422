import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Accreditation, Car, EventCode } from '@ideenherd';
import { DataService } from '@ideenherd/core/data';
import { map, Subscription, switchMap } from 'rxjs';
import { SINECTROUTES } from '../../app-routing.module';
import { AppService } from '../../app.service';
import { CarViewModel } from '../view-models/car.view-model';
import { EventViewModel } from '../view-models/event.view-model';
import { DxToastComponent } from 'devextreme-angular';
import DevExpress from 'devextreme';
import { TokenService } from '@ideenherd/authentication';

@Component({
  selector: 'event-verifier',
  templateUrl: './event-verifier.component.html',
  styleUrls: ['./event-verifier.component.scss'],
})
export class EventVerifierComponent implements OnInit {
  @ViewChild('toast') dxToast: DxToastComponent;
  public showSplash = true;
  public eventCode: string;
  private subscriptions: Subscription[] = [];
  public toastPosition: DevExpress.PositionConfig = { at: 'top', my: 'center', offset: '0 100' };
  public carList: CarViewModel[] = [];

  constructor(
    private router: Router,
    private dataService: DataService,
    private appService: AppService,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
    this.eventCode = localStorage.getItem('eventCode');
    this.appService.showBackButton = false;
    setTimeout(() => {
      this.showSplash = !this.showSplash;
    }, 1500);
    this.subscriptions.push(this.appService.backButtonS$.subscribe(() => {
      if (window['deviceReady'] == true) {
        document.addEventListener("backbutton", function (e) {
          this.backToOrderList();
        });
      }
      this.backToOrderList();
    }));
  }

  private backToOrderList = (): void => {
    this.router.navigateByUrl('/event-verifier');
    this.appService.showBackButton = false;
  }

  getEventDetail() {
    if (this.eventCode) {
      const eventCode: EventCode = {
        login: this.eventCode,
      };
      this.subscriptions.push(this.dataService.getEventByCode<Accreditation>(eventCode).subscribe((eventDetail) => {
        localStorage.setItem('eventCode', this.eventCode);
        this.appService.eventDetail = new EventViewModel(
          eventDetail.a_event_
        )
      }, (error) => {
        this.dxToast.visible = true;
      }, () => {
        this.appService.showBackButton = true;
        this.router.navigate([SINECTROUTES.EVENT_DETAIL]);
      }
      )
      );
    } else {
      this.dxToast.visible = true;
    }
  }


}
